<script lang="ts" setup>
import type { NavigationLink } from 'global'

const { t } = useI18n()

const navigation = ref([
  {
    label: t('nav_bible'),
    url: t('nav_bible_url'),
  },
  {
    label: t('nav_feedback'),
    url: t('nav_feedback_url'),
  },
] as NavigationLink[])

const setNav = () => {
  navigation.value = [
    {
      label: t('nav_bible'),
      url: t('nav_bible_url'),
    },
    {
      label: t('nav_feedback'),
      url: t('nav_feedback_url'),
    },
  ]
}

watch(
  () => t('brand'),
  () => {
    setNav()
  },
)
</script>
<template>
  <div class="bg-gray-50 dark:bg-gray-800">
    <LayoutNavBar :navigation="navigation" />
    <div
      class="bg-gray-50 dark:bg-gray-800 max-w-4xl min-h-screen p-2 md:p-8 mx-auto text-zinc-200"
    >
      <slot />
    </div>
  </div>
</template>
